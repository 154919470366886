import axiosClient from "./axiosClient";
import { AddTenant } from "@/interfaces/addTenant.interface";

// API calls from BaseURL + custom end

export default {
  addTenant(payload: AddTenant) {
    return axiosClient().post("tenant", payload);
  },

  getAllTenants() {
    return axiosClient().get("tenant");
  },

  getTenantById(id: string) {
    return axiosClient().get(`tenant/${id}`);
  },

  updateTenantById(id: string, payload: AddTenant) {
    return axiosClient().patch(`tenant/${id}`, payload);
  },

  deleteTenantById(id: string) {
    return axiosClient().delete(`tenant/${id}`);
  },
};
