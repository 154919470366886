import { defineStore } from "pinia";

export const useSnackBarStore = defineStore("snackbar", {
  state: () => ({
    snackbar: false,
    text: "an unknown event happened",
    color: "white",
    icon: "mdi-information",
  }),

  getters: {},

  actions: {
    openSnackbar(text: string, color: string, icon?: string) {
      this.text = text;
      this.color = color;
      if (icon) {
        this.icon = icon;
      }

      this.snackbar = true;
    },
  },
});
