import axiosClient from "./axiosClient";

// API calls from BaseURL + custom end

export default {
  health() {
    return axiosClient().get("/health");
  },
  version() {
    return axiosClient().get("/version");
  },
};
