import { defineStore } from "pinia";
import TenantService from "@/api/tenant.service";
import { useSnackBarStore } from "@/stores/snackBar.store";
import { AddTenant } from "@/interfaces/addTenant.interface";
import { Tenant } from "@/interfaces/Tenant.interface";

export const useTenantStore = defineStore("tenant", {
  state: () => ({
    tenants: [] as Tenant[],
  }),

  getters: {},

  actions: {
    async addTenant(payload: AddTenant) {
      try {
        await TenantService.addTenant(payload);
        this.openSnackbarSuccess("added");
      } catch (err) {
        this.openSnackbarError("adding");
      }
    },

    async getAllTenants() {
      try {
        // this.openSnackbarSuccess("loaded");
        return await TenantService.getAllTenants().then((res) => {
          this.tenants = res.data.records;
        });
      } catch (e) {
        this.openSnackbarError("Loading all");
      }
    },

    async getTenantById(id: string) {
      try {
        return await TenantService.getTenantById(id);
      } catch (e) {
        this.openSnackbarError("Loading");
      }
    },

    async updateTenantById(id: string, tenant: AddTenant) {
      try {
        return await TenantService.updateTenantById(id, tenant);
      } catch (e) {
        this.openSnackbarError("Loading");
      }
    },

    async deleteTenantById(id: string) {
      try {
        await TenantService.deleteTenantById(id);
        this.openSnackbarSuccess("deleted");
      } catch (e) {
        this.openSnackbarSuccess("Deleting");
      }
    },

    //  OPEN SNACKBAR
    openSnackbarSuccess(value: string) {
      const snackBarStore = useSnackBarStore();
      snackBarStore.openSnackbar("tenant successfully " + value, "success");
    },

    openSnackbarError(value: string) {
      const snackBarStore = useSnackBarStore();
      snackBarStore.openSnackbar(
        value + " tenant failed",
        "error",
        "mdi-alert"
      );
    },
  },
});
