import { TENANT_ABILITIES } from "@/interfaces/addTenant.interface";
import { storeToRefs } from "pinia";

function UserRoleHandler(store: any) {
  const { user } = storeToRefs(store);
  const getUserRole = () => {
    if (!user) {
      throw "no user found";
    }
    if (user?.value.role.global) {
      return Role.SUPER_ADMIN;
    }
    if (!user?.value.role.tenantAdmin) {
      if (
        user?.value.tenant.abilities.includes(TENANT_ABILITIES.SERVICE_PROVIDER)
      ) {
        return Role.SERVICE_ADMIN;
      } else if (
        user?.value.tenant.abilities.includes(TENANT_ABILITIES.CLIENT)
      ) {
        return Role.CLIENT_ADMIN;
      } else {
        throw "no user found";
      }
    } else {
      if (
        user?.value.tenant.abilities.includes(TENANT_ABILITIES.SERVICE_PROVIDER)
      ) {
        return Role.SERVICE_USER;
      } else if (
        user?.value.tenant.abilities.includes(TENANT_ABILITIES.CLIENT)
      ) {
        return Role.CLIENT_USER;
      } else {
        throw "no user found";
      }
    }
  };

  const activeUser = () => {
    try {
      return user.value;
    } catch {
      return;
    }
  };
  const isSuperAdmin = () => {
    try {
      return user?.value.role.global;
    } catch {
      return;
    }
  };

  const isTenantAdmin = () => {
    try {
      return user?.value.role.tenantAdmin;
    } catch {
      return;
    }
  };

  const checkUserAbility = (ability: Abilities) => {
    return user?.value.tenant.abilities.includes(ability);
  };
  return {
    isTenantAdmin,
    getUserRole,
    activeUser,
    isSuperAdmin,
    checkUserAbility,
  };
}

export default UserRoleHandler;

export enum Abilities {
  SERVICE_PROVIDER = "serviceProvider",
  CLIENT = "client",
}

export enum Role {
  SUPER_ADMIN = "SUPER_ADMIN",
  SERVICE_ADMIN = "TENANT_ADMIN",
  CLIENT_ADMIN = "CLIENT_ADMIN",
  SERVICE_USER = "SERVICE_USER",
  CLIENT_USER = "CLIENT_USER",
}
