import Vue, { CreateElement } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { createPinia, PiniaVuePlugin } from "pinia";
import i18n from "./i18n";
import UserRoleHandler from "@/Roles/UserRoleHandler";

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
Vue.use(UserRoleHandler);

const pinia = createPinia();

new Vue({
  router,
  vuetify,
  pinia,
  i18n,
  render: (h: CreateElement) => h(App),
}).$mount("#app");
