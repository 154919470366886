import axiosClient from "./axiosClient";

// API calls from BaseURL + custom end

export default {
  login(payload: object) {
    return axiosClient().post("auth/login", payload);
  },

  refreshToken(token: string) {
    return axiosClient().post("auth/refreshToken", { refreshToken: token });
  },

  getDecode() {
    return axiosClient().get("auth/decode");
  },

  resetPassword(email: string) {
    return axiosClient().post("auth/resetPassword", { email: email });
  },

  setPassword(hash: string, password: string) {
    return axiosClient().post(`auth/setPassword/${hash}`, {
      password: password,
    });
  },
};
