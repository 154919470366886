import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //  add customization here
        primary: "#1a4851",
        success: "#a4d5a6",
        error: "#ef9a9a",
        accent: "#a5b5d6",
        back: "#a2a2a2",
      },
      dark: {
        //  add customization here
        primary: "#296d70",
      },
    },
  },
});
