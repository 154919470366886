import { defineStore } from "pinia";
import AuthService from "@/api/auth.service";
import { useSnackBarStore } from "@/stores/snackBar.store";
import { AddUser } from "@/interfaces/addUser.interface";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: undefined,
    refreshToken: undefined,
    isRefreshingToken: false,
    failedQueue: <any>[],
    user: undefined as AddUser | undefined,
  }),

  getters: {
    getToken: (state) => state.token,
    getRefreshToken: (state) => state.refreshToken,
    getIsRefreshingToken: (state) => state.isRefreshingToken,
    getFailedQueue: (state) => state.failedQueue,
    getUser: (state) => state.user,
  },

  actions: {
    // LOGIN
    async login(payload: { username: string; password: string }) {
      try {
        const loginData = await AuthService.login(payload);
        this.token = loginData.data.access_token;
        this.refreshToken = loginData.data.refresh_token;
        this.user = loginData.data.user;

        this.saveToLocalStorage();
        this.openSnackbarSuccess();
      } catch (err) {
        this.openSnackbarError();
        return false;
      }
    },

    // SET PASSWORD
    async setPassword(hash: string, password: string) {
      try {
        return await AuthService.setPassword(hash, password).then((res) => {
          this.token = res.data.access_token;
          this.refreshToken = res.data.refresh_token;
          this.user = res.data.user;

          console.log(res.data);

          this.saveToLocalStorage();
        });
      } catch (e) {
        console.log(e);
      }
    },

    saveToLocalStorage() {
      localStorage.setItem("token", this.token!);
      localStorage.setItem("refreshToken", this.refreshToken!);

      const user = JSON.stringify(this.user);
      localStorage.setItem("user", user);
    },

    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");

      this.clearLogin();
    },

    getFromLocalStorage(): {
      token: string | undefined | null;
      refreshToken: string | undefined | null;
      user: AddUser | undefined;
    } | null {
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");
      const user = JSON.parse(localStorage.getItem("user")!);

      if (!token && !refreshToken && !user) {
        return null;
      }

      this.user = user;

      return {
        token: token,
        refreshToken: refreshToken,
        user: user,
      };
    },

    autoAuthAfterRefresh() {
      const authInformation = this.getFromLocalStorage();

      if (!authInformation) {
        return;
      }

      // @ts-ignore
      this.token = authInformation.token;

      // @ts-ignore
      this.refreshToken = authInformation.refreshToken;
      this.user = authInformation.user;
    },

    // REFRESH TOKEN
    async refreshTokenAction(token: string) {
      try {
        return await AuthService.refreshToken(token);
      } catch (e) {
        console.log(e);
      }
    },

    saveLogin(payload: { user: any; token: string; refreshToken: string }) {
      this.user = payload.user;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.token = payload.token;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.refreshToken = payload.refreshToken;
    },

    clearLogin() {
      this.token = undefined;
      this.user = undefined;
      this.refreshToken = undefined;
    },

    clearFailedQueue() {
      this.failedQueue = [];
    },

    pushFailedRequest(payload: { resolve: any; reject: any }) {
      this.failedQueue.push(payload);
    },

    setIsRefreshing(isRefreshing: boolean) {
      this.isRefreshingToken = isRefreshing;
    },

    //  OPEN SNACKBAR
    openSnackbarSuccess() {
      const snackBarStore = useSnackBarStore();
      snackBarStore.openSnackbar("login successful", "success");
    },
    openSnackbarError() {
      const snackBarStore = useSnackBarStore();
      snackBarStore.openSnackbar("login failed", "error", "mdi-alert");
    },
  },
});
