import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    name: "login",
    path: "/login",
    component: () => import("../views/forms/LoginView.vue"),
  },
  {
    name: "test",
    path: "/test",
    component: () => import("../views/TestView.vue"),
  },
  {
    name: "setPassword",
    path: "/setPassword/:hash",
    component: () => import("../views/forms/setPasswordView.vue"),
  },
  {
    name: "addUser",
    path: "/addUser",
    component: () => import("../views/forms/AddUserView.vue"),
  },
  {
    name: "editUserList",
    path: "/editUserList",
    component: () => import("../views/forms/EditUserListView.vue"),
  },
  {
    name: "editUser",
    path: "/editUser/:id",
    component: () => import("../views/forms/AddUserView.vue"),
  },
  {
    name: "addTenant",
    path: "/addTenant",
    component: () => import("../views/forms/AddEditTenantView.vue"),
  },
  {
    name: "editTenant",
    path: "/editTenant/:id",
    component: () => import("../views/forms/AddEditTenantView.vue"),
  },
  {
    name: "addService",
    path: "/addService",
    component: () => import("../views/forms/AddServiceView.vue"),
  },
  {
    name: "editService",
    path: "/editService/:id",
    component: () => import("../views/forms/AddServiceView.vue"),
  },
  {
    name: "addServiceTag",
    path: "/addServiceTag",
    component: () => import("../views/forms/AddEditServiceTagView.vue"),
  },
  {
    name: "editServiceTagList",
    path: "/editServiceTagList",
    component: () => import("../views/lists/EditServiceTagListView.vue"),
  },
  {
    name: "editServiceTag",
    path: "/editServiceTag/:id",
    component: () => import("../views/forms/AddEditServiceTagView.vue"),
  },
  {
    name: "servicePortfolio",
    path: "/servicePortfolio",
    component: () => import("../views/overviews/ServicePortfolioView.vue"),
  },
  {
    name: "addVehicle",
    path: "/addVehicle",
    component: () => import("../views/forms/AddEditVehicleView.vue"),
  },
  {
    name: "editVehicle",
    path: "/editVehicle/:id",
    component: () => import("../views/forms/AddEditVehicleView.vue"),
  },
  {
    name: "editVehicleState",
    path: "/editVehicleState/:id",
    component: () => import("../views/overviews/EditVehicleStateView.vue"),
  },
  {
    name: "addProtocol",
    path: "/addProtocol/:id/:oldState/:newState/:justComment",
    component: () => import("../views/forms/AddProtocolView.vue"),
  },
  {
    name: "addVehicleType",
    path: "/addVehicleType",
    component: () => import("../views/forms/AddVehicleTypeView.vue"),
  },
  {
    name: "editVehicleTypeList",
    path: "/editVehicleTypeList",
    component: () => import("../views/lists/EditVehicleTypeListView.vue"),
  },
  {
    name: "editVehicleType",
    path: "/editVehicleType/:id",
    component: () => import("../views/forms/AddVehicleTypeView.vue"),
  },
  {
    name: "editServiceCostsList",
    path: "/editServiceCostsList/:id",
    component: () => import("../views/lists/EditServiceCostsListView.vue"),
  },
  {
    name: "editServiceCosts",
    path: "/editServiceCosts/:clientId/:serviceId/:tenantId",
    component: () => import("../views/forms/EditServiceCostsView.vue"),
  },
  {
    name: "jobOverview",
    path: "/jobOverview",
    component: () => import("../views/overviews/JobOverviewView.vue"),
  },
  {
    name: "forgetPassword",
    path: "/forgetPassword",
    component: () => import("../views/forms/ForgetPasswordView.vue"),
  },
  {
    name: "contractOverview",
    path: "/contractOverview",
    component: () => import("../views/overviews/ContractOverviewView.vue"),
  },
  {
    name: "addContract",
    path: "/addContract",
    component: () => import("../views/forms/AddEditContractView.vue"),
  },
  {
    name: "editContract",
    path: "/editContract/:id",
    component: () => import("../views/forms/AddEditContractView.vue"),
  },
  {
    name: "calender",
    path: "/calender",
    component: () => import("../views/overviews/CalendarOverviewView.vue"),
  },
  {
    name: "changeDeadline",
    path: "/changeDeadline/:id",
    component: () => import("../views/forms/ChangeDeadlineView.vue"),
  },
];

const router: VueRouter = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
