import axios from "axios";
import { useAuthStore } from "@/stores/auth.store";
import { useSnackBarStore } from "@/stores/snackBar.store";

export default () => {
  const authStore = useAuthStore();

  const axiosClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL!,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  // HANDLING TOKEN IN HTTP REQUESTS

  axiosClient.interceptors.request.use(
    (config) => {
      if (useAuthStore().getToken) {
        // err "config.headers object is possibly 'undefined' - maybe better solution than just !"
        // eslint-disable-next-line
        config.headers!.Authorization = `bearer ${useAuthStore().getToken}`;
      }
      return config;
    },
    (error) => {
      throw Promise.reject(error);
    }
  );

  // HANDLING HTTP RESPONSE ERRORS

  axiosClient.interceptors.response.use(
    (response) => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    },
    (error) => {
      if (error.response.status) {
        switch (error.response.status) {
          case 400:
            alert("An error occurred, Bad Request");
            break;
          // handle invalid (or expired) token ...
          case 401: {
            const isRefreshingToken = authStore.getIsRefreshingToken;

            // Check if token is being refreshed
            // if true, return promise which will be resolved later
            if (isRefreshingToken) {
              return new Promise((resolve, reject) => {
                // Push to failed request Queue in Store
                authStore.pushFailedRequest({
                  resolve,
                  reject,
                });
              })
                .then(async (token) => {
                  // Set new Token as header
                  error.config.headers.Authorization = "bearer " + token;

                  // Wait for Request to finish and return data
                  const request = await axios.request(error.config);
                  return request.data;
                })
                .catch((err) => {
                  return Promise.reject(err);
                });
            }

            // Set Is Refreshing to true
            authStore.setIsRefreshing(true);

            // Return a Promise, which bill be resolved later
            // When the Data for the Token Refresh is available
            // And being called for
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
              try {
                // Get current Refresh Token from Store
                const refreshToken = authStore.getRefreshToken;

                // Dispatch a new RefreshToken Call
                let refreshData = undefined;
                if (refreshToken) {
                  refreshData = await authStore.refreshTokenAction(
                    refreshToken
                  );
                }

                // Save received login data (e.g Token, User and RefreshToken) in Auth Store
                if (refreshData) {
                  authStore.saveLogin({
                    user: refreshData.data.user,
                    token: refreshData.data.access_token,
                    refreshToken: refreshData.data.refresh_token,
                  });
                }

                if (refreshData) {
                  // Add new Auth Token to Headers
                  error.config.headers.Authorization =
                    "bearer " + refreshData.data.access_token;

                  // Process old Requests with new Token
                  processQueue(null, refreshData.data.access_token);
                }

                // Wait for old Request to finish
                const request = await axios.request(error.config);

                // Set Refreshing to false
                authStore.setIsRefreshing(false);

                // Resolve Promise with Data
                resolve(request.data);
              } catch (e) {
                // Set Refreshing to false
                authStore.setIsRefreshing(false);

                // Process Failed Queue with error and no token
                processQueue(e);

                // Navigate to Login and reject this promise
                return await onTokenGenerationFailed(() => reject(e));
              }
            });
          }
          // todo catch all alerts with the snackbar
          case 403:
            alert("An error occurred, Forbidden");
            break;
          case 404:
            // eslint-disable-next-line no-case-declarations
            const snackBarStore = useSnackBarStore();
            snackBarStore.openSnackbar("Error, page not found", "error");
            break;
          case 500:
            alert("An unknown error occurred");
            break;
        }
        return Promise.reject(error.response);
      }
    }
  );

  function processQueue(error: any, token = null) {
    // Get current failed queue list
    const failedQueue = authStore.getFailedQueue;

    // Go through all Failed promises and resolve them
    for (const failedPromise of failedQueue) {
      // If error occured reject this promise
      if (error) {
        failedPromise.reject(error);
        continue;
      }

      // Else resolve with token
      failedPromise.resolve(token);
    }

    // Clear Failed Queue
    authStore.clearFailedQueue();
  }

  async function onTokenGenerationFailed(reject: any) {
    authStore.clearLogin();
    // await router.push({ name: "login" });
    reject();
  }

  return axiosClient;
};
